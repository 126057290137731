import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Logger } from './logger';



let logger = Logger;

logger.log('ecp-iframe');
logger.log('version: %o', window.version);
logger.log('commit: %o', window.commit);
logger.log('env: %o', process.env.NODE_ENV);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
