import React, { Component } from 'react';
import { logger } from '../logger';
import op from 'object-path';

const clickAreaBuffer = 2;

export default class Volume extends Component {
	
	constructor(props) {
		super(props);

		this.scrubbing = false;
		this.barWidth = 80;
		this.currentX = 0;
		this.amp = props.amp;

		this.state = {
			muted: this.amp.getMuted(),
			volume: this.amp.getVolume(),
			progress: (this.amp.getVolume() * this.barWidth),
			...this.props
		}

		this.onVolumeChange = this.onVolumeChange.bind(this);
		this.onMarkerMouseDown = this.onMarkerMouseDown.bind(this);
		this.onWindowMouseUp = this.onWindowMouseUp.bind(this);
		
		this.addAmpListeners();

		//logger.log('Volume: constructor - state:%o', this.state);
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.amp.multitonKey != prevProps.amp.multitonKey){
			this.amp = this.props.amp;
			this.addAmpListeners();
		}
	}

	addAmpListeners() {
		this.amp.addEventListener('volumechange', this.onVolumeChange);
	}

	onVolumeChange(data) {
		this.setState({
			muted: this.amp.getMuted(),
			volume: this.amp.getVolume(),
			progress: (this.amp.getVolume() * this.barWidth)
		});
	};

	handleTapEvent(data) {
		logger.log('Volume: handleTapEvent - data:%o', data);

		if (this.state.muted) {
			this.amp.setMuted(false);
			this.props.onVolumeClick('unmute');
		}
		else {
			this.amp.setMuted(true);
			this.props.onVolumeClick('mute');
		}
	}

	setScrubPosition(xPos) {
		if (xPos <= clickAreaBuffer) {
			xPos = 0;
		}

		this.currentX = xPos;

		let newPercent = Math.max(0, Math.min(1, xPos / this.barWidth));

		logger.info('Volume: setScrubPosition - xPos:%o width:%o perc:%o', xPos, this.barWidth, newPercent);
		
		this.amp.setVolume(newPercent);
		this.props.onVolumeChange('volume_change');
	};

	onMouseUp(evt) {
		this.scrubbing = false;
		//logger.info('Volume: onMouseUp - offsetX:%o', evt.nativeEvent.offsetX);
		this.setScrubPosition(evt.nativeEvent.offsetX);
	}

	onWindowMouseUp(evt) {
		this.scrubbing = false;

		//logger.info('Volume: onWindowMouseUp - currentX:%o', this.currentX);

		document.removeEventListener("mouseup", this.onWindowMouseUp);
		this.setScrubPosition(this.currentX);
	}

	onSliderMove(e) {
		if (this.scrubbing) {
			//logger.info('Volume: onSliderMove - offsetX:%o', e.nativeEvent);
			this.setScrubPosition(e.nativeEvent.offsetX)
		}
	}

	onMarkerMouseDown(evt) {
		this.scrubbing = true;
		document.addEventListener("mouseup", this.onWindowMouseUp, false);
	}

	onMarkerMove(evt) {
		evt.stopPropagation();
	}

	onMarkerMouseUp(evt) {
		evt.stopPropagation();
	}

	
	
	render() {
		//logger.log('Volume: render - state:%o', this.state);

		let styles = this.state.styles;

		let data_state = '';
		if(this.state.muted) {
			data_state = 'mute'
		}
		
		return (
			<div className={styles.volume} data-state={data_state}>
				<span onClick={(evt) => {this.handleTapEvent(evt)}}>
					<button className={`${styles.icon} ${styles.volume}`} data-state={data_state} title="Volume"></button>
				</span>
				<div className={styles.volume_box}
					onMouseMove={this.onSliderMove.bind(this)} 
					onMouseUp={(evt) => this.onMouseUp(evt)}>
			
						<div className={styles.volume_bar}>
							<span className={styles.volume_progress} style={{width: this.state.progress + 'px'}}>
								<span className={styles.volume_marker}
									onMouseUp={(evt) => this.onMarkerMouseUp(evt)} 
									onMouseDown={(evt) => this.onMarkerMouseDown(evt)}
									onMouseMove={this.onMarkerMove.bind(this)} >
								</span>
							</span>
						</div>
				
				</div>
			</div>
		)
	}
	
}
