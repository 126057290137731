import moment from 'moment';
import queryString from 'querystring-browser';

//initialize logger as not enabled
let logEnabled = false;
let startTime = moment();

let search = document.location.search.replace(/^\?/, '');
let parsedQs = queryString.parse(search);

if (process.env.NODE_ENV == 'development' || parsedQs.logs) {
    logEnabled = true;
}

export const logger = {
    log: (...args) => {
        if (logEnabled === true || logEnabled === 'true') {
            //let time = moment().diff(startTime, 'seconds', true);
            args[0] = moment().format('HH:mm:ss') + ' [ReactEcp] ' + args[0];
            console.log(...args);
        }
    },

    info: (...args) => {
        if (logEnabled === true || logEnabled === 'true') {
            //let time = moment().diff(startTime, 'seconds', true);
            args[0] = moment().format('HH:mm:ss') + ' [ReactEcp] ' + args[0];
            console.info(...args);
        }
    },

    warn: (...args) => {
        if (logEnabled === true || logEnabled === 'true') {
            args[0] = moment().format('HH:mm:ss') + ' [ReactEcp] ' + args[0];
            console.warn(...args);
        }
    },

    error: (...args) => {
        if (logEnabled === true || logEnabled === 'true') {
            args[0] = moment().format('HH:mm:ss') + ' [ReactEcp] ' + args[0];
            console.error(...args);
        }
    }
};
