/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import ReactEcp from 'react-ecp';
import { Logger } from '../logger';
import queryString from 'querystring-browser';
import axios from 'axios';
import 'promise-polyfill/src/polyfill';
import { isMobileOnly, isTablet, isBrowser } from "react-device-detect";
import isEmpty from 'lodash/isEmpty';

// import Script from 'react-load-script';

/**
 * -----------------------------------------------------------------------------
 * React Component: VideoTest
 * -----------------------------------------------------------------------------
 */

let logger = Logger;


const videoStyle = {
	position: 'relative',
	width: '100%',
	paddingBottom: '56.25%',
	overflow: 'hidden',
};

// const videoConfig = {
// 	title: 'Spieth’s Final Round in Under Three Minutes',
// 	poster: 'http://pdlmasters-a.akamaihd.net//2018/thumbnail/15232367332533_0001_O_1280x720.jpg',
// 	shareUrl: 'http://test.share.url',
// 	streams: [
// 		{
// 			cdn: 'fox_dvr',
// 			url: 'https://fnurtmp-f.akamaihd.net/i/FNRADIO_1@92141/master.m3u8',
// 			mimeType: 'application/x-mpegURL',
// 			streamType: 'dvr',
// 		},
// 		{
// 			cdn: 'azure_live',
// 			url:
// 				'http://b028.wpc.azureedge.net/80B028/Samples/a38e6323-95e9-4f1f-9b38-75eba91704e4/5f2ce531-d508-49fb-8152-647eba422aec.ism/Manifest(format=m3u8-aapl)',
// 			mimeType: 'application/x-mpegURL',
// 			streamType: 'live',
// 		},
// 		{
// 			cdn: 'Akamai',
// 			url:
// 				'//mastersvod2017-vh.akamaihd.net/i/2018/15232367332533_0001_O_,1280x720_3500,960x540_3000,960x540_2400,960x540_1700,640x360_1100,512x288_600,384x216_300,256x144_100,kbps.mp4.csmil/master.m3u8?__b__=1700',
// 			mimeType: 'application/x-mpegURL',
// 			streamType: 'vod',
// 		},
// 		{
// 			cdn: 'Level3',
// 			url: 'http://mastersarchive.akamaized.net/replay/1516/20180408/index_3000_av-p.m3u8',
// 			mimeType: 'application/x-mpegURL',
// 			streamType: 'vod',
// 		},
// 	],
// 	tracks: [
// 		{
// 			kind: 'captions',
// 			type: 'text/vtt',
// 			srclang: 'en',
// 			src: 'https://usopenvideo-a.akamaihd.net/2018/153654049823448_0001_O__en.vtt',
// 		},
// 		{
// 			kind: 'captions',
// 			type: 'text/cea-608',
// 			srclang: 'en',
// 			src: '',
// 		},
// 	],
// 	/* ads: [{
// 		"type": "vast",
// 		 "tag": "https://pubads.g.doubleclick.net/gampad/ads?slotname=/124319096/external/ad_rule_samples&sz=640x480&ciu_szs=300x250&unviewed_position_start=1&output=xml_vast3&impl=s&env=vp&gdfp_req=1&ad_rule=0&vad_type=linear&vpos=preroll&pod=1&ppos=1&lip=true&min_ad_duration=0&max_ad_duration=30000&cust_params=deployment%3Ddevsite%26sample_ar%3Dpremidpost&url=&video_doc_id=short_onecue&cmsid=496&kfa=0&tfcd=0",
// 	}], */
// 	companionAds: [{ id: 'companion-container', width: 384, height: 384 }],
// 	startSeconds: 0,
// 	autoplay: true,
// 	autoplayPolicy: 'unmuted',
// };

// const playerConfig = {
// 	akamaiAcceleration: true,
// 	captions: 'default',
// 	/*
// 	controls: {
// 		type: 'react',
// 		js: '/assets/controls.js',
// 		css: '/assets/controls.css'
// 	},
// 	 */
// 	controls: {
// 		type: 'custom',
// 	},

// 	cdns: [
// 		{
// 			id: 'Akamai',
// 			percent: 100,
// 		},
// 		{
// 			id: 'Level3',
// 			percent: 0,
// 		},
// 	],
// 	ui: {
// 		giantPlayButton: true,
// 		breakpoint: 550,
// 		toolbar: {
// 			buttons: {
// 				left: {
// 					playPause: {
// 						display: true,
// 						position: 'left',
// 					},
// 					skipBack: {
// 						display: true,
// 						position: 'left',
// 					},
// 					live: {
// 						display: true,
// 						position: 'left',
// 					},
// 					time: {
// 						display: true,
// 						position: 'left',
// 					},
// 					volume: {
// 						display: true,
// 						position: 'left',
// 					},
// 				},
// 				right: {
// 					quality: {
// 						display: false,
// 						position: 'right',
// 					},
// 					panel: {
// 						display: false,
// 						position: 'right',
// 					},
// 					popOpen: {
// 						display: false,
// 						position: 'right',
// 					},
// 					captions: {
// 						display: true,
// 						position: 'right',
// 					},
// 					share: {
// 						display: false,
// 						position: 'right',
// 					},
// 					fullscreen: {
// 						display: true,
// 						position: 'right',
// 					},
// 				},
// 				other: {
// 					extra: {
// 						display: true,
// 						position: 'right',
// 					},
// 				},
// 			},
// 		},
// 	},
// 	analytics: {
// 		conviva: {
// 			enable: ['live', 'dvr'],
// 			serviceUrl: 'https://cws.conviva.com',
// 			key: '5999669ec2fc5940232ab4a6f392cdfc9a8a631f',
// 			syndicator: 'WIMBLEDON',
// 		},
// 		akamai: {
// 			enable: ['live', 'dvr'],
// 			beacon: 'https://ma715-r.analytics.edgekey.net/config/beacon-17171.xml',
// 		},
// 	},
// 	appStrings: [
// 		['Download the Masters iOS App at ', 'https://itunes.apple.com/us/app/the-masters-tournament/id309025938?mt=8'],
// 		[
// 			'Download the Masters Android App at ',
// 			'https://play.google.com/store/apps/details?id=com.ibm.events.android.masters',
// 		],
// 	],
// };
export default class VideoPage extends Component {
	constructor(props) {
		super(props);

		let search = document.location.search.replace(/^\?/, '');
		this.parsedQs = queryString.parse(search);
		logger.log('parsedQs: %o',this.parsedQs);

		this.videoConfigLoaded = false;
		this.playerConfigLoaded = false;
		this.message = false;
	}
	componentDidMount() {
		logger.log('[VideoPage] - componentDidMount state:%o', this.state);
		let videoConfigURL = `./config/${this.parsedQs.id}_${this.parsedQs.lang}_videoConfig.json`;
		let playerConfigURL = `./config/${this.parsedQs.id}_playerConfig.json`;

		if (this.parsedQs.id && this.parsedQs.lang) {

			// We're using axios instead of Fetch
			axios
			// The API we're requesting data from
			.get(videoConfigURL)
			// Once we get a response, we'll map the API endpoints to our props
			.then(response => this.setState({videoConfig: response.data}))
			.catch(error => this.setState({
				error,
				"message":`Unable to load videoConfig: ${videoConfigURL} (${error.message})`
			}));

			// We're using axios instead of Fetch
			axios
			// The API we're requesting data from
			.get(playerConfigURL)
			// Once we get a response, we'll map the API endpoints to our props
			.then(response => this.setState({playerConfig: response.data}))
			.catch(error => this.setState({
				error,
				"message":`Unable to load playerConfig: ${playerConfigURL} (${error.message})`
			}));
		} else {
			logger.log("location: %o:",window.location);
			this.setState({
				"message":"This URL is invlid:  " + window.location.href
			})
		}
	}

	componentDidUpdate(prevProps, prevState) {

	}

	onUserAction(data) {
		logger.log('[VideoTest] onUserAction - data:%o', data);
	}

	onReady(player) {
		logger.log('[VideoTest] onReady - player:%o', player);
		this.player = player;
	}

	onStart() {
		logger.log('[VideoTest] onStart');
	}

	onPlay() {
		logger.log('[VideoTest] onPlay');
	}

	onPause() {
		logger.log('[VideoTest] onPause');
	}

	render() {
		logger.log('[VideoTestPage] render state:%o', this.state);
		logger.log('[VideoTestPage] render this.message:%o', this.message);
		// if(this.state.videoConfig){
		// 	let autoplay = isIOS ? false : true;
		// 	let videoConfig = Object.assign(this.state.videoConfig, {autoplay});
		// 	logger.log('[VideoTestPage] render videoConfig:%o', videoConfig);
		// }
		if (this.state && this.state.playerConfig) {
			let playerConfig = this.state.playerConfig;
			let screenType = "";
			if(isBrowser){
				screenType = "PC";
			}else if(isMobileOnly){
				screenType = "Phone";
			}else if(isTablet){
				screenType = "Tablet";
			}
	
			let url = "";
			if (this.state.playerConfig && !isEmpty(playerConfig.analytics)) {
				playerConfig.analytics = {
					...playerConfig.analytics,
					convivaData: {
						...playerConfig.analytics.conviva,
						custom: {
							...playerConfig.analytics.conviva.custom,
							screenType: screenType,
							pageURL: `/index.html?id=${this.parsedQs.id}&lang=${this.parsedQs.lang}`,
							cdn: playerConfig.cdns[0].id
						},
					},
				};
			}
	
			logger.log('[VideoTestPage] playerConfig:%o', playerConfig);
	
			// "url":"http://aac-lh.akamaihd.net/i/aacEN_1@151314/master.m3u8", //live url
			return (
					<div className="video-container">
						<div style={videoStyle}>
							{
								this.state.videoConfig && this.state.playerConfig ?
									<ReactEcp
									id="ecp_iframe"
									ampBasePath="/libs/"
									playerConfig={playerConfig}
									videoConfig={this.state.videoConfig}
									forcePseudoFullscreen={false}
									isPseudoFullscreen={false}
									onReady={amp => this.onReady(amp)}
									onUserAction={data => this.onUserAction(data)}
									onStart={() => this.onStart()}
									onPlay={() => this.onPlay()}
									onPause={() => this.onPause()}
									colors={{
										highlightColor: '#FFFFFF',
										toolbarBackground: '#000000',
										progressBackground: '#FFFFFF'}
									}
								/>
							:
								this.state.message ?
									<div className="message">{this.state.message}</div>
								:	<div className="message"> Loading player </div>
							}
						</div>
					</div>
			);
		} else {
			return null
		}
		
	}
}
