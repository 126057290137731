import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { logger } from '../logger';
import op from 'object-path';
import Measure from 'react-measure';
import ReactCursorPosition, { INTERACTIONS }  from 'react-cursor-position';
import Overlay from './overlay';
import PlayPause from './PlayPause';
import SkipBack from './SkipBack';
import Live from './Live';
import Time from './Time';
import Volume from './Volume';
import ProgressBar from './ProgressBar';
import Panel from './Panel';
import PopOpen from './PopOpen';
import Captions from './Captions';
import Fullscreen from './Fullscreen';
import Download from './Download';
import Share from './Share';
import ShareOverlay from './ShareOverlay';
import AudioTracks from './AudioTracks';
import Extra from './Extra';
import {formatSecs, unformatSecs} from './Utils';
import {FocusWithin} from 'react-focus-within';

import controlStyles from '../sass/controls/controls.scss';

// const buttonStringsOld = {
// 	'play': '<button class="icon ecp-playBtn icon-VideoPlay" title="Play"></button>',
// 	'rewind': '<button class="icon ecp-rewindBtn icon-Skip30" title="Skip Back"></button>',
// 	'live': '<button class="icon ecp-liveBtn icon-Live" title="Live"></button>',
// 	'time': '<div class="timeText"><span class="curTimeText"></span><span class="durTimeText"></span></div>',
// 	'volume': '<div class="volume"><button class="icon ecp-muteBtn icon-Volume" title="Volume"></button><div class="volume_box"><div class="volume_bar"><span class="volume_progress"><span class="volume_marker"></span></span></div></div></div>',
// 	'fullscreen': '<button class="icon ecp-fullScreenBtn icon-Fullscreen" title="FullScreen"></button>',
// 	'captions': '<button class="icon ecp-captionsBtn icon-Captions" data-state="disabled" title="CC"></button>',
// 	'quality': '<button class="icon ecp-qualitysBtn icon-Quality" data-state="disabled" title="Quality"></button>',
// 	'sharing': '<button class="icon ecp-shareBtn icon-Share" data-state="disabled" title="Share"></button>',
// 	'panel': '<button class="icon ecp-panelBtn icon-Panel" data-state="disabled" title="Picture-in-Picture"></button>',
// 	'pop_open': '<button class="icon ecp-popOpenBtn icon-PopOpen" data-state="disabled" title="Pop-Out"></button>',

// 	<button className="icon extra icon-Extra" title="Extra"></button>
// };

const skipTime = 30;
const initialProps = {
	playState: 'inactive',
	temporalType: 'vod',
	tracks: [],
	toolbar: {
		visible: false,
		over: false
	},
	dimensions: {
		width: -1,
		height: -1
	},
	smallView: false, 
	extraOpen: false,
	altAudioOpen: false,
	shareOpen: false,
	panelOpen: false,
	allowToolbar: true
};

class Controls extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			...this.props,
			...initialProps,
			showUnMute: op.get(this.props.config, 'ui.unmuteOverlay', true),
			showTimeShare: false,
			userConvertedTime: null,
			selectedAudio: 0
		}
		
		this.captionsBtn = React.createRef();
		this.amp = props.amp;
		this.overTimer;
		this.leaveTimer;
		this.lastX;
		this.lastY;
		this.shareRightPos = -1;
		this.toolbarHeight = '50px';
		this.breakpoint = op.get(this.props.config, 'ui.breakpoint', 560);
		this.tapUnmute = false;
		this.minCogItems = op.get(this.props.config, 'ui.minCogItems', 1);
		this.showTooltip = op.get(this.props.config, 'ui.showTooltip', false);
		this.toolTipText = new Object;
		this.playPauseRef = React.createRef();
		let toolTips = op.get(this.props, 'toolTipText', false);
		if(toolTips){
			for (let [key, value] of Object.entries(toolTips)){
				let a = key.split(':');
				let seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
				this.toolTipText[seconds] = value;
			}
		}

		this.onStart = this.onStart.bind(this);
		this.onPlay = this.onPlay.bind(this);
		this.onPause = this.onPause.bind(this);
		this.onPlaying = this.onPlaying.bind(this);
		this.onWaiting = this.onWaiting.bind(this);
		this.onEnded = this.onEnded.bind(this);
		this.onTimeUpdate = this.onTimeUpdate.bind(this);
		this.onTracksLoaded = this.onTracksLoaded.bind(this);
		this.onAdPlaying = this.onAdPlaying.bind(this);
		this.onAdPaused = this.onAdPaused.bind(this);
		this.onAdEnded = this.onAdEnded.bind(this);
		this.startToolbarTimer = this.startToolbarTimer.bind(this);
		this.onSeeked = this.onSeeked.bind(this);
		this.onSeeking = this.onSeeking.bind(this);
		this.keypressListener = this.keypressListener.bind(this);
		this.keyDownListener = this.keyDownListener.bind(this);
		this.audioOverlayVisible = this.audioOverlayVisible.bind(this);

		this.measureComponent;
		this.waitTime;

		this.updateStyles(this.props.colors); 

		this.addAmpListeners();

		logger.log('Controls: constructor - state:%o', this.state);
	}

	keypressListener(e){		
		// logger.log('Controls: e.code:%o', e.code);
		if(this.props.preventKeyboard){
			return;
		}

		if(e.code == "Space"){
			// let ele = document.querySelector(`#controls-${this.props.mediaId}`);
			const parentElem = this.props.parentElem();
			if(parentElem.current === document.activeElement || op.get(this.state, 'config.ui.keyboarFocusOverride', false)){
				// logger.log('Controls: this vid has focus!');	
				this.onPlayPauseClick();
			}			
			logger.log('Controls: keypressListener - parentElem:%o', parentElem);
		}

	}

	keyDownListener(e){
		// logger.log('Controls: e.code:%o', e.code);
		if(this.props.preventKeyboard){
			return;
		}
		
		const parentElem = this.props.parentElem();
		if(e.code == "ArrowLeft"){
			if(parentElem.current.contains(document.activeElement) || op.get(this.state, 'config.ui.keyboarFocusOverride', false)){
				let newTime = Math.max(this.amp.getCurrentTime() - 5, 0);//minus 5 seconds
				this.amp.setCurrentTime(newTime);
				this.userAction('arrowBack5', {'action': 'arrowBack5'});
			}
		}
		if(e.code == "ArrowRight"){
			if(parentElem.current.contains(document.activeElement) || op.get(this.state, 'config.ui.keyboarFocusOverride', false)){
				let newTime = Math.max(this.amp.getCurrentTime() + 5, 0);//minus 5 seconds
				this.amp.setCurrentTime(newTime);
				this.userAction('arrowFwd5', {'action': 'arrowFwd5'});
			}
		}
	}
	
	componentDidMount() {
		//logger.log('Controls: componentDidMount - measure:%o', this.measureComponent);
		this.measureComponent.measure();
		window.addEventListener("keypress", e => this.keypressListener(e), false);
		window.addEventListener("keydown", e => this.keyDownListener(e), false);
	}

	componentWillUnmount() {
		//logger.log('Controls: componentWillUnmount - amp:%o', this.amp);
		this.amp = null;
		window.removeEventListener("keypress", e => this.keypressListener(e), false);
		window.removeEventListener("keydown", e => this.keyDownListener(e), false);
	}
	
	componentWillReceiveProps(nextProps) {
		//logger.log('Controls: componentWillReceiveProps - nextProps:%o', nextProps);

		this.setState({
			...nextProps
		})
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		logger.log('Controls: componentDidUpdate - prevProps:%o this.props:%o', prevProps, this.props);	
		
		if (this.amp == null || this.props.amp.multitonKey != prevProps.amp.multitonKey) {
			logger.log('Controls: componentDidUpdate - key:%o', this.props.amp.multitonKey);	

			this.tapUnmute = false;
			//this.removeAmpListeners();
			this.amp = this.props.amp;
			this.addAmpListeners();

		}

		if (prevProps.mediaId !== this.props.mediaId) {
			this.tapUnmute = false;
			this.removeAmpListeners();
			this.reset(this.props.poster);
			logger.log('Controls: componentDidUpdate - this.props:%o', this.props.amp);	
		}
	}

	reset(poster) {
		logger.log('Controls: reset');
		let dimensions = this.state.dimensions;
		let smallView = this.state.smallView;

		this.setState({
			...initialProps,
			poster: poster,
			dimensions: dimensions,
			smallView: smallView,
			tracks: [],
			showUnMute: op.get(this.props.config, 'ui.unmuteOverlay', true)
		});
		
		this.addAmpListeners();
	}

	addAmpListeners() {
		if (this.amp){
			this.amp.addEventListener('started', this.onStart);
			this.amp.addEventListener('playing', this.onPlaying);
			this.amp.addEventListener('play', this.onPlay);
			this.amp.addEventListener('pause', this.onPause);
			this.amp.addEventListener('waiting', this.onWaiting);
			this.amp.addEventListener('ended', this.onEnded);
			this.amp.addEventListener('timeupdate', this.onTimeUpdate);
			this.amp.addEventListener('seeked', this.onSeeked);
			this.amp.addEventListener('seeking', this.onSeeking);
	
			if (this.amp.ads != null){
				this.amp.ads.addEventListener('playing', this.onAdPlaying);
				this.amp.ads.addEventListener('pause', this.onAdPaused);
				this.amp.ads.addEventListener('ended', this.onAdEnded);
			}

			if (this.amp.captioning){
				logger.info('Controls: addAmpListeners - adding tracksloaded');
				this.amp.captioning.addEventListener('tracksloaded', this.onTracksLoaded);
			}
		}
	}

	removeAmpListeners() {
		if (this.amp){
			this.amp.removeEventListener('playing', this.onPlaying);
			this.amp.removeEventListener('play', this.onPlay);
			this.amp.removeEventListener('pause', this.onPause);
			this.amp.removeEventListener('waiting', this.onWaiting);
			this.amp.removeEventListener('ended', this.onEnded);
			this.amp.removeEventListener('timeupdate', this.onTimeUpdate);
			this.amp.removeEventListener('seeked', this.onSeeked);
			this.amp.removeEventListener('seeking', this.onSeeking);
	
			if (this.amp.ads != null){
				this.amp.ads.removeEventListener('playing', this.onAdPlaying);
				this.amp.ads.removeEventListener('ended', this.onAdEnded);
			}

			if (this.amp.captioning){
				logger.info('Controls: removeAmpListeners - removing tracksloaded');
				this.amp.captioning.removeEventListener('tracksloaded', this.onTracksLoaded);
			}
		}
	}

	setWaiting(state, src='') {
		logger.info('Controls: setWaiting:%o src:%o time:%o', state.waiting, src, this.amp.getCurrentTime());
		if (state.waiting) {
			this.waitTime = -1;
		}
		
		this.setState(state);
	}

	// **********************
	// *** event handlers ***
	// **********************
	onPlayStateChange(e) {
		if (e){
			logger.info('Controls: onPlayStateChange - ', e.data.value);
		}
	}

	onSeeking() {
		//logger.info('Controls: onSeeking');
		this.setWaiting({waiting: true}, 'seeking');
	}

	onSeeked() {
		//logger.info('Controls: onSeeked');
		this.setWaiting({waiting: false}, 'seeked');
	}

	/**
	 * on start of video check muted state
	 * if not muted, set state of unmute overlay
	 */
	onStart() {
		logger.info('Controls: onStart - muted:%o', this.amp.getMuted());
		if (!this.amp.getMuted()){
			this.setState({
				showUnMute: false
			});
		}
	}

	onPlay() {
		logger.info('Controls: onPlay - muted:%o', this.amp.getMuted());
		this.setState({
			status: 'play',
			adPlaying: false
		});
		this.setWaiting({waiting: true}, 'play');
	}

	onPause(data) {
		//logger.info('Controls: onPause - data:%o', data);
		this.setState({
			status: 'pause'
		});
	};

	onPlaying() {
		//logger.info('Controls: onPlaying - media:%o', this.amp.media);

		this.setWaiting({
			waiting: false,
			playState: 'active',
			temporalType: this.amp.media.temporalType  //not autodetected, just from config
		}, 'playing')
	};

	onWaiting(data) {
		//logger.info('Controls: onWaiting - data:%o', data);
		this.setWaiting({waiting: true}, 'waiting');
	};

	onEnded(data) {
		logger.info('Controls: onEnded ');
		if (this.state.playState == 'active'){
			this.setState({
				playState: 'ended'
			})
		}
	};

	onTimeUpdate(data) {
		//logger.log('Controls: onTimeUpdate - time:%o dur:%o', this.amp.getCurrentTime(), this.amp.getDuration());
		if (this.state.waiting) {
			//logger.log('Controls: wait:%o onTimeUpdate - time:%o', this.waitTime, this.amp.getCurrentTime());
			if (this.waitTime == -1) {
				this.waitTime = this.amp.getCurrentTime()
			}
			else if (this.waitTime != this.amp.getCurrentTime()){
				this.setWaiting({waiting: false}, 'timechange');
			}
			
		}
	}

	onTracksLoaded = function() {
		let tracks = this.amp.captioning.getTracks();
		logger.info('Controls: onTracksLoaded %o use608:%o', tracks, this.props.useCustom608);

		if (this.props.useCustom608){
			//logger.info('Controls: onTracksLoaded useCustom608');
			this.onCustom608();
			return;
		}

		let track = [];

		logger.info('Controls: onTracksLoaded - tracks:%o', tracks.length);

		for (var i=0; i<tracks.length; i++) {
			logger.info('Controls: onTracksLoaded - tracks:%o', JSON.stringify(tracks[i].data));

			if (tracks[i].data.label == "Inline") {
				tracks[i].mode = 'hidden';
				tracks[i].enabled = false;
			}
			else if (tracks[i].kind == 'subtitles' || tracks[i].kind == 'captions'){
				track.push(
					{
						'label': tracks[i].data.label,
						'language': tracks[i].data.language,
						'kind': tracks[i].data.kind,
						'index': i
					}
				);
			}
		}

		logger.info('Controls: onTracksLoaded - track:%o', JSON.stringify(track));

		this.setState({
			tracks: track
		})
	}

	onCustom608() {
		logger.log('Controls: onCustom608');
		let track = [];
		track.push(
			{
				'label': 'English',
				'language': 'en',
				'kind': 'captions',
				'index': 0
			}
		);

		this.setState({
			tracks: track
		})
	}

	onAdPlaying() {
		//onAdStart('preroll');
		logger.info('onAdPlaying');
		this.setState({
			adPlaying: true,
			status: 'play'
		})
	};

	onAdPaused() {
		//onAdStart('preroll');
		logger.info('onAdPaused');
		this.setState({
			status: 'pause'
		})
	};

	onAdEnded() {
		//onAdEnd('preroll');
		logger.info('onAdEnd');
		this.setState({
			adPlaying: false
		})
	}

	onResize(contentRect) {
		//logger.info('Controls: onResize - contentRect:%o breakpoint:%o', contentRect.bounds, this.breakpoint);

		let smallView = (contentRect.bounds.width < this.breakpoint);
		//logger.info('Controls: onResize - contentRect:%o breakpoint:%o count:%o', contentRect.bounds, this.breakpoint, this.countButtons('right'));
		this.setState({ 
			dimensions: contentRect.bounds,
			smallView: smallView
		})
	}


	// *************************
	// *** user interactions ***
	// *************************
	onPlayPauseClick() {
		if (this.state.status == 'play') {
			this.amp.pause();
			this.userAction('playPause', {'action': 'pause'})
		}
		else {
			this.amp.play();
			this.userAction('playPause', {'action': 'play'})
		}
	}

	onSkipBackClick() {
		let newTime = Math.max(this.amp.getCurrentTime() - skipTime, 0);
		this.amp.setCurrentTime(newTime);
		this.userAction('skipBack', {'action': 'skip_back'})
	}

	onProgressClick(data) {
		//logger.info('Controls: onProgressClick - data:%o', data);
		this.userAction('progress', {'action': 'seek'})
	}

	onVolumeClick(data) {
		logger.info('Controls: onVolumeClick - data:%o', data);
		this.userAction('volume', {'action': data});
		this.setState({
			showUnMute: false
		})
	}

	onVolumeChange(data) {
		logger.info('Controls: onVolumeChange - data:%o', data);
		this.userAction('volume', {'action': data});
		this.setState({
			showUnMute: false
		})
	}

	onPanelClick(data) {
		//logger.info('Controls: onPanelClick - data:%o', data);
		this.userAction('panel', {'action': this.state.panelOpen ? 'panel_close' : 'panel_open'});

		this.setState({
			panelOpen: !this.state.panelOpen
		})

		this.closeExtra();
	}

	onPopOpenClick(data) {
		logger.info('Controls: onPopOpenClick - data:%o', data);
		this.userAction('popOpen', {'action': 'popOpen'});

		this.closeExtra();
	}

	onCaptionsClick(data) {
		logger.info('Controls: onCaptionsClick - data:%o', data);
		this.userAction('captions', {'action': data});

		this.closeExtra();
	}

	onFullscreenClick(data) {
		logger.info('Controls: onFullscreenClick - data:%o', data);
		if (data == 'native_fullscreen') {
			this.userAction('native_fullscreen', {'action': 'open'});
		}
		else {
			this.userAction('fullscreen', {'action': data});
		}

		this.closeExtra();
	}

	onShareClick(data) {
		let status = this.state.shareOpen ? 'Share_close' : 'Share_open';
		this.userAction('status', {'action': status});

		this.setState({
			shareOpen: !this.state.shareOpen
		})

		this.closeExtra();
	}

	onExtraClick(data) {
		let status = this.state.extraOpen ? 'Extra_close' : 'Extra_open';
		this.userAction('extra', {'action': status});

		this.setState({
			extraOpen: !this.state.extraOpen
		})
	}

	onShareLinkClick(data) {
		//logger.info('Controls: onShareLinkClick - data:%o', data);
		this.userAction('share', {'action': data});

		if (data != 'Share:Copy'){
			this.closeExtra();
			this.closeShare();
		}
	}

	showShareAtTime(showTimeShare){
		let status = showTimeShare ? 'Share_Time_On' : 'Share_Time_Off';
		this.userAction('showShareAtTime', {'action': status});
		let userConvertedTime = showTimeShare ? this.state.userConvertedTime : null;
		this.setState({showTimeShare, userConvertedTime});
	}

	setShareTime(e){
		console.log(e.target.value);
		this.setState({userConvertedTime: e.target.value});
	}

	onShareCloseClick() {
		let status = this.state.shareOpen ? 'Share_close' : 'Share_open';
		this.userAction('status', {'action': status});

		this.setState({
			shareOpen: !this.state.shareOpen
		})

		this.closeExtra();
	}

	userAction(type, data){
		if(this.state.onUserAction) {
			this.state.onUserAction(type, data)
		}
	}

	closeExtra() {
		this.setState({
			extraOpen: false
		})
	}

	audioOverlayVisible(altAudioOpen){
		if(altAudioOpen === true || altAudioOpen === false){			
			this.setState({
				altAudioOpen
			});
		}else{
			let {altAudioOpen} = this.state;
			altAudioOpen = altAudioOpen ? false : true;
			this.setState({
				altAudioOpen
			});
		}
		let openClose = altAudioOpen ? 'altAudio_open' : 'altAudio_close';
		this.userAction('audioOverlay', {'action': openClose});
	}

	closeShare() {
		this.setState({
			shareOpen: false
		})
	}

	captions(active) {
		logger.info('Controls: captions - active:%o', active);

		this.captionsBtn.current.set(active);
	}

	onOverlayInteraction(data) {
		//logger.info('Controls: onOverlayInteraction - data:%o', data);
		window.clearTimeout(this.leaveTimer);

		if (data.isActive) {
			//logger.info('Controls: onInteraction - showControls');
			if( !this.state.toolbar.visible){
				this.updateToolbarState({
					visible: true
				})
				this.startToolbarTimer();
			}
			else if ( (data.position.x != this.lastX || data.position.y != this.lastY)){
				//logger.info('Controls: onInteraction - not over');
				this.lastX = data.position.x;
				this.lastY = data.position.y;
				this.startToolbarTimer();
			}
			
		}
		else if (data.isPositionOutside && !this.state.toolbar.over){
			//logger.info('Controls: onInteraction - isOutside');
			this.startToolbarTimer(1500);
		}
		//logger.info('Controls: onInteraction - data:%o', data);
	}

	handleTapEvent(data) {
		//let xPos = data.nativeEvent.offsetX || data.nativeEvent.layerX;
		// this.playPauseRef.current.focus();
		logger.log('Controls: handleTapEvent - data:%o state:%o', data.currentTarget, this.state);
		this.updateToolbarState({
			visible: true
		});
		this.startToolbarTimer();

		if (this.state.showUnMute){
			let policy = this.amp.config.autoplayPolicy;
			let values = akamai.amp.AutoplayPolicy;
			logger.log('Controls: handleTapEvent - policy:%o values:%o', policy, values);

			if (policy === values.MUTED) {
				logger.log('Controls: handleTapEvent - tap:%o policy:%o', this.tapUnmute, policy);
				//this.tapUnmute = true;
				this.amp.setMuted(false);
				this.setState({
					showUnMute: false
				})
				this.userAction('unmuteOverlay', {'action': 'overlay_unmute'})
			}
		}
		else {
			if(op.get(this.state, 'config.ui.touchScreenPause', false)){
				this.onPlayPauseClick();
			}
		}

	}

	toolbarEnter() {
		logger.info('Controls: toolbarEnter');
		window.clearTimeout(this.overTimer);
		window.clearTimeout(this.leaveTimer);
		this.updateToolbarState({
			visible: true,
			over: true
		});
	}

	toolbarLeave(isFocused) {
		logger.info('Controls: toolbarLeave - isFocused:%o', isFocused);
		// this.updateToolbarState({
		// 	over: false
		// });
		if(!isFocused){
			this.leaveTimer = window.setTimeout(() => {
				this.updateToolbarState(
					{
						visible: false,
						over: false
					}
				); 
			}, 1500);
		}
	}

	updateToolbarState(newState) {
		if (this.props.mode == 'none' && newState.visible) {
			newState.visible = false
		}

		let temp = this.state.toolbar;
		let toolbar = {
			...temp,
			...newState
		}
		logger.info('Controls: updateToolbarState - toolbar:%o', toolbar);
		if (this.amp){
			this.setState({
				toolbar: toolbar
			})
		}
	}

	startToolbarTimer(timeout=2500) {
		//logger.info('Controls: startToolbarTimer - timer:%o', this.overTimer);
		window.clearTimeout(this.overTimer);
		this.overTimer = window.setTimeout(() => {
			this.updateToolbarState(
				{
					visible: false,
					over: false
				}
			);
		}, timeout);
	}

	clearToolbarTimer() {
		logger.info('Controls: clearToolbarTimer');
		window.clearTimeout(this.overTimer);
		window.clearTimeout(this.leaveTimer);
	}


	gpbClick() {
		logger.log('Controls: gpbClick - %o', this.amp.getMedia());
		this.amp.play();
		this.userAction('gpb', {'action': 'play'})
	}

	getPoster() {
		//logger.log('Controls: getPoster - autoplay:%s autoplayPoster:%s', this.props.autoplay, this.props.autoplayPoster);

		let posterStyle = { backgroundImage: "url(" + this.props.poster + ")" };
		if (!this.props.autoplayPoster) {
			posterStyle = { backgroundImage: "none", backgroundColor: "#000000" }
		}

		//logger.log('Controls: getPoster - state:%o', this.state);

		if(this.state.playState == 'inactive' && this.props.autoplay) {
			return (
				<div className={controlStyles.playerPoster} style={posterStyle} >
					<div className={`${controlStyles.loaderContainer}`} data-state='show'>
						<div className={controlStyles.loader}>Loading...</div>
					</div>
				</div>
			)
		}
		else if (this.props.poster && (this.state.playState == 'inactive' || this.state.playState == 'ended')) {
			return (
				<div className={controlStyles.playerPoster} style={{ backgroundImage: "url(" + this.props.poster + ")" }} onClick={() => this.gpbClick()}>
					<div className={`${controlStyles.center} ${controlStyles.play}`}></div>
				</div>
			)
		}
		else if((this.state.playState == 'inactive' || this.state.playState == 'ended')) {
			return (
				<div className={controlStyles.playerPoster} style={{ backgroundImage: "none", backgroundColor: "#000000" }} onClick={() => this.gpbClick()}>
					<div className={`${controlStyles.center} ${controlStyles.play}`}></div>
				</div>
			)
		}
		else if((this.state.adPlaying && this.state.status == 'pause')) {
			return (
				<div className={controlStyles.playerPoster} style={{ backgroundImage: "none"}} onClick={() => this.gpbClick()}>
					<div className={`${controlStyles.center} ${controlStyles.play}`}></div>
				</div>
			)
		}
		else {
			return null;
		}
	}

	getButtons(side) {
		let toolbar = op.get(this.state, 'config.ui.toolbar', false);

		if (toolbar){
			let configButtons = toolbar.buttons[side];
			let buttons = [];

			for (let key in configButtons) {
				if (configButtons[key].display){
					buttons.push(key);
				}
				else {
					//buttons.push(key);
				}
			}

			let pos = buttons.indexOf('share');
			if (pos >= 0 && side == 'right'){
				//logger.log('Controls: getButtons - pos:%o', (buttons.length - pos));
				this.shareRightPos = (buttons.length - pos);
			}
			
			//logger.log('Controls: getButtons - buttons:%o', buttons);
			return buttons
		}
		else {
			return [];
		}
	}

	countButtons(side) {
		let toolbar = op.get(this.state, 'config.ui.toolbar', false);

		if (toolbar){
			let count = 0;
			let configButtons = toolbar.buttons[side];

			for (let key in configButtons) {
				if (configButtons[key].display){
					count++;
				}
			}

			return count;
		}
		else {
			return 0;
		}
	}

	setPanel(state) {
		this.setState({
			panelOpen: state
		})
	}

	allowToolbar(state) {
		this.setState({
			allowToolbar: state
		})

		if (!state) {
			this.updateToolbarState(
				{
					visible: false,
					over: false
				}
			);
		}
	}

	seek(time) {
		if (this.amp) {
			if (time > -1){
				this.amp.setCurrentTime(time);
			}
			else {
				this.amp.goLive();
			}
		}
	}

	switchAudio(trackId){
		let audioTracks = this.amp.tracks.data.audioTracks.list;
		/** 3/15/22 this is currently not supported in most browsers **/
		// let audioTracks = this.amp.getMediaElement().audioTracks; 

		logger.info('Controls: switchAudio - trackId:%o audioTracks:%o', trackId, audioTracks);		
		//switch the tracks
		for (let x = 0; x < audioTracks.length; x++) {
			if(x == trackId){
				audioTracks[x].enabled = true;
			}else{
				audioTracks[x].enabled = false;
			}
		}
		this.setState({selectedAudio: trackId});
	}

	moveCaptions(toolbar) {
		let target = 'amp-caption-text';
	
		if (document.getElementsByClassName(target).length > 0 ){
			if (!toolbar){
				//document.getElementsByClassName('akamai-caption-text')[0].setAttribute("style", "bottom: 0px");
				document.getElementsByClassName(target)[0].classList.add(`${controlStyles.captions_bottom}`);
				document.getElementsByClassName(target)[0].classList.remove(`${controlStyles.captions_float}`);
			}
			else {
				//document.getElementsByClassName('akamai-caption-text')[0].setAttribute("style", "bottom:" + this.toolbarHeight);
				document.getElementsByClassName(target)[0].classList.add(`${controlStyles.captions_float}`);
				document.getElementsByClassName(target)[0].classList.remove(`${controlStyles.captions_bottom}`);
			}
		}
	}

	updateStyles(colors) {
		let classList = [
			`button.${controlStyles.icon}.${controlStyles.skipBack}:not(.${controlStyles.touch}):hover`,
			`button.${controlStyles.icon}.${controlStyles.playPause}:not(.${controlStyles.touch}):hover`,
			`button.${controlStyles.icon}.${controlStyles.share}:not(.${controlStyles.touch}):hover`,
			`button.${controlStyles.icon}.${controlStyles.extra}:not(.${controlStyles.touch}):hover`,
			`button.${controlStyles.icon}.${controlStyles.captions}:not(.${controlStyles.touch}):hover`,
			`button.${controlStyles.icon}.${controlStyles.fullscreen}:not(.${controlStyles.touch}):hover`,
			`button.${controlStyles.icon}.${controlStyles.panel}:not(.${controlStyles.touch}):hover`,
			`button.${controlStyles.icon}.${controlStyles.popOpen}:not(.${controlStyles.touch}):hover`,
			`button.${controlStyles.icon}.${controlStyles.volume}:not(.${controlStyles.touch}):hover`,
			`button.${controlStyles.icon}.${controlStyles.live}:not(.${controlStyles.touch}):hover`,
			`button.${controlStyles.icon}.${controlStyles.altAudio}:not(.${controlStyles.touch}):hover`,
		];
		let bgClassList = [
			`.${controlStyles.ecp_controls} .${controlStyles.toolbar} .${controlStyles.progress} .${controlStyles.progress_cover}`,
			`.${controlStyles.ecp_controls} .${controlStyles.toolbar} .${controlStyles.volume} .${controlStyles.volume_progress}`
		]
		let toolbar = `.${controlStyles.ecp_controls} .${controlStyles.toolbar}`;
		let progressBar = `.${controlStyles.main_controls} .${controlStyles.toolbar} .${controlStyles.progress} .${controlStyles.play_progress}`;
		let progressCover = `.${controlStyles.main_controls} .${controlStyles.toolbar} .${controlStyles.progress} .${controlStyles.progress_cover}`;
		let tooltip = `.${controlStyles.ecp_controls} .${controlStyles.toolbar} .${controlStyles.progress} .${controlStyles.progress_bar} .${controlStyles.tooltip}`;
		let tooltipArrow = `.${controlStyles.ecp_controls} .${controlStyles.toolbar} .${controlStyles.progress} .${controlStyles.progress_bar} .${controlStyles.tooltip}::after`;

		
		let styleIndex = 0;
		for (let y=0; y<document.styleSheets.length; y++) {
			let classes = [];
			if (document.styleSheets[y].href) {
				classes = new Array();
			}
			else {
				classes = document.styleSheets[y].cssRules
			}
			//logger.log('Controls: updateStyles - index:%o classes:%o', y, classes);
			//logger.log('Controls: updateStyles - index:%o controlStyles:%o', y, controlStyles['button']);
			for (let z = 0; z < classes.length; z++) {
				if (classes[z].selectorText == `button.${controlStyles.icon}`) {
					styleIndex = y;
					y = document.styleSheets.length;
					break;
				}
			}
		}
		//logger.log('Controls: updateStyles - styleIndex:%o', styleIndex);
		//logger.log('Controls: updateStyles - classList:%o', classList);
		//logger.log('Controls: updateStyles - classList:%o', bgClassList);
		//logger.log('Controls: updateStyles - progressBar:%o', progressCover)

		let classes = document.styleSheets[styleIndex].cssRules;
		//logger.log('Controls: updateStyles - classes:%o', classes);
		for (let x = 0; x < classes.length; x++) {
			//logger.log('Controls: updateStyles - classes[x].selectorText:%o', classes[x].selectorText);
			if (classList.indexOf(classes[x].selectorText) > -1){
				//logger.log('Controls: updateStyles - match:%o selector:%o', classList.indexOf(classes[x].selectorText), classes[x].selectorText);
				classes[x].style.color = colors.highlightColor;
			}
			else if (bgClassList.indexOf(classes[x].selectorText) > -1) {
				//logger.log('Controls: updateStyles - match:%o selector:%o', bgClassList.indexOf(classes[x].selectorText), classes[x].selectorText);
				classes[x].style.backgroundColor = colors.highlightColor;
			}
			else if (toolbar == classes[x].selectorText) {
				//logger.log('Controls: updateStyles - match:%o selector:%o', bgClassList.indexOf(classes[x].selectorText), classes[x]);
				this.toolbarHeight = classes[x].height;
				classes[x].style.backgroundColor = colors.toolbarBackground;
			}
			else if (progressBar == classes[x].selectorText) {
				//logger.log('Controls: updateStyles - match:%o selector:%o', bgClassList.indexOf(classes[x].selectorText), classes[x].selectorText);
				classes[x].style.backgroundColor = colors.progressBackground;
			}
			else if (progressCover == classes[x].selectorText) {
				//logger.log('Controls: updateStyles - match:%o selector:%o', bgClassList.indexOf(classes[x].selectorText), classes[x].selectorText);
				classes[x].style.backgroundColor = colors.progressBackground;
			}
			else if (tooltip == classes[x].selectorText) {
				//logger.log('Controls: updateStyles - match:%o selector:%o', bgClassList.indexOf(classes[x].selectorText), classes[x].selectorText);
				classes[x].style.backgroundColor = colors.toolbarBackground;
				//classes[x].style.backgroundColor = colors.toolbarBackground;
			}
			else if (tooltipArrow == classes[x].selectorText) {
				//logger.log('Controls: updateStyles - match:%o selector:%o', classes[x].style, eval('classes[x].style["border-top"]'));
				classes[x].style['border-top'] = `5px solid ${colors.toolbarBackground}`;
				//classes[x].style.backgroundColor = colors.toolbarBackground;
			}
		}
		

	}
	
	render() {
		logger.log('Controls: render - props:%o custom608:%o state:%o', this.props, this.props.useCustom608, this.state);

		let toolbarClasses = [controlStyles.toolbar];
		if (this.state.temporalType == 'vod') {
			toolbarClasses.push(controlStyles.type_vod);
		}
		else if (this.state.temporalType == 'dvr') {
			toolbarClasses.push(controlStyles.type_dvr);
		}
		else if (this.state.temporalType == 'live') {
			toolbarClasses.push(controlStyles.type_live);
		}

		if (!this.state.allowToolbar) {
			toolbarClasses.push(controlStyles.disabled);
		}

		if (this.props.isFullscreen) {
			toolbarClasses.push(controlStyles.fullscreen);
		}
		  
		if (!this.state.adPlaying && (this.state.toolbar.visible || this.state.toolbar.over)) { 
			toolbarClasses.push(controlStyles.show); 
			this.moveCaptions(true);
		}
		else {
			toolbarClasses.push(controlStyles.hide); 
			toolbarClasses.push(controlStyles.invisible); 
			this.moveCaptions(false);
		}

		if (this.state.smallView) { 
			if (this.countButtons('right') > this.minCogItems){
				toolbarClasses.push(controlStyles.smallView); 
			}
			else {
				toolbarClasses.push(controlStyles.smallViewSingle); 
			}
		}

		let controlsClasses = [controlStyles.main_controls];
		if (this.props.mode != 'none' && (!this.state.adPlaying || 
			(this.state.adPlaying && this.state.status == 'pause') || 
			(this.state.adPlaying && this.amp.getMuted()) ||
			this.state.status == 'pause')) { 
			controlsClasses.push(controlStyles.show); 
		}
		else {
			controlsClasses.push(controlStyles.hide); 
		}

		//logger.log('Controls: render - toolbarClasses:%o', toolbarClasses);
		
		let waiting_state = this.state.waiting ? 'show' : '';
		let extra_state = this.state.extraOpen ? 'show' : '';
		let {altAudioOpen, selectedAudio} = this.state;
		let appStrings = op.get(this.state, 'config.appStrings', false);

		//logger.log('Controls: render - state:%o', this.state);
		let unmute_state = (this.state.showUnMute) ? 'show' : '';

		let currentTime = Math.floor(this.amp.getCurrentTime());
		let convertedTime = '';
		if(this.state.userConvertedTime){
			currentTime = unformatSecs(this.state.userConvertedTime);
			convertedTime = this.state.userConvertedTime;
		}else{			
			convertedTime = formatSecs(currentTime);
		}
		
		let shareUrl = this.props.shareUrl;
		
		if(op.get(this.props.config, 'ui.shareAtTime', false) && this.state.showTimeShare){
			shareUrl = shareUrl.indexOf("?") != -1 ? shareUrl+"&t="+currentTime : shareUrl+"?t="+currentTime;
		}

		return (
			<Measure
				bounds
				measure={this}
				onResize={(contentRect) => {this.onResize(contentRect)}}
				ref={(component) => {
					this.measureComponent = component
				}}
			>
				{({ measureRef }) =>
				  
				<div ref={measureRef} id={`controls-${this.props.mediaId}`} className={controlsClasses.join(' ')}>					
					<div id="overlay" onClick={(evt) => {this.handleTapEvent(evt)}}>
						<ReactCursorPosition
							className={controlStyles.position_target}
							activationInteractionMouse={INTERACTIONS.HOVER}
							onPositionChanged={(data) => this.onOverlayInteraction(data)}
						>
							<Overlay amp={this.amp} styles={controlStyles}/>
						</ReactCursorPosition>
					</div>
					
					<AudioTracks 
						amp={this.amp} 
						styles={controlStyles} 
						isOpen={altAudioOpen} 
						closeHandler={() => this.audioOverlayVisible(false)} 
						switchAudio={(idx) => this.switchAudio(idx)} 
						selected={selectedAudio} 
						colors={this.props.colors}
					/> 

					<div className={`${controlStyles.loaderContainer}`} data-state={waiting_state}>
						<div className={controlStyles.loader}>Loading...</div>
					</div>
					
					<div id="unmute_overlay" className={`${controlStyles.unMuteOverlay}`} data-state={unmute_state} onClick={(evt) => {this.handleTapEvent(evt)}}>
						<button 
							className={`${controlStyles.icon} ${controlStyles.unmuteIndicator}`} 
							title="UnMute">
						</button>
					</div>
					<FocusWithin onFocus={() => this.toolbarEnter()} onBlur={() => this.toolbarLeave(false)}>
					{({isFocused, focusProps}) => (
						<div {...focusProps} id="toolbar" className={toolbarClasses.join(' ')} 
							onMouseEnter={() => this.toolbarEnter()} 
							onMouseLeave={() => this.toolbarLeave(isFocused)}
							tabIndex="0">
							<div className={controlStyles.centerholder}>
								<div className={controlStyles.centercol}>
									<ProgressBar 
										mediaId={this.props.mediaId}
										amp={this.amp} 
										styles={controlStyles} 
										dimensions={this.state.dimensions} 
										showTooltip={this.showTooltip}
										toolTipText={this.toolTipText}
										onClick={(data) => {this.onProgressClick(data)}} />
								</div>
							</div>
							<div className={controlStyles.leftcol}>
								<div id="leftSideButtons" className={controlStyles.colRow}>
									{
										this.getButtons('left').map(button => {
											switch(button) {
												case 'playPause':
													return (<PlayPause status={this.state.status} amp={this.amp} key={button} styles={controlStyles}
														onClick={() => {this.onPlayPauseClick()}} ref={this.playPauseRef} />)
													break;
												case 'skipBack':
													if (this.state.temporalType == 'vod' || this.state.temporalType == 'dvr'){
														return (<SkipBack amp={this.amp} key={button} styles={controlStyles} 
															onClick={() => {this.onSkipBackClick()}} />)
													} else { return null}
													break;
												case 'live':
													if (this.state.temporalType == 'live' || this.state.temporalType == 'dvr'){
														return (<Live amp={this.amp} 
															temporalType={this.state.temporalType}
															key={button} 
															styles={controlStyles}/>)
													} else { return null}
													break;
												case 'time':
													return (<Time amp={this.amp} key={button} styles={controlStyles}/>)
													break;
												case 'volume':
													return (<Volume amp={this.amp} key={button} styles={controlStyles}
														onVolumeClick={(data) => {this.onVolumeClick(data)}} 
														onVolumeChange={(data) => {this.onVolumeChange(data)}} />)
													break;
												case 'altAudio':
													if(this.amp.tracks.data.audioTracks.list.length > 1){
														return (<button 
															className={`${controlStyles.icon} ${controlStyles.altAudio}`} 
															style={{}} 
															onClick={() => {this.audioOverlayVisible('toggle')}} 
															title="Alt Audio Menu" />
														)
													} else { return null }
													break;
												default:
													return null
													break;
											}
											
										})
									}
								</div>
							</div>
							<div className={controlStyles.rightcol}>
								<div id="extraButtons" className={`${controlStyles.colRow} ${controlStyles.rightExtra}`}>
									{
										this.getButtons('other').map(button => {
											switch(button) {
												case 'extra':
													return (<Extra status={this.state.status} key={button} styles={controlStyles}  
														amp={this.amp} 
														onClick={() => {this.onExtraClick()}} />)
													break;
												default:
													return null
													break;
											}
											
										})
									}
								</div>
								<div id="rightSideButtons" className={`${controlStyles.colRow} ${controlStyles.rightMain}`} data-state={extra_state}>
								{
									this.getButtons('right').map(button => {
										switch(button) {
											case 'panel':
												return (<Panel status={this.state.status} key={button} styles={controlStyles} 
													active={this.state.panelOpen}
													onClick={() => {this.onPanelClick()}} />)
												break;
											case 'popOpen':
												return (<PopOpen status={this.state.status} key={button} styles={controlStyles}  
													onClick={() => {this.onPopOpenClick()}} />)
												break;
											case 'captions':
												return (<Captions status={this.state.status} key={button} styles={controlStyles}  
													amp={this.amp} 
													mediaId={this.props.mediaId}
													ref={this.captionsBtn}
													tracks={this.state.tracks}
													useCustom608={this.props.useCustom608}
													active={op.get(this.state, 'settings.captions', false)}
													onClick={(show) => {this.onCaptionsClick(show)}} />)
												break;
											case 'share':
												return (<Share status={this.state.status} key={button} styles={controlStyles}  
													shareUrl={shareUrl}
													amp={this.amp} 
													onClick={() => {this.onShareClick()}} />)
												break;
											case 'download':
												return (
													<Fragment>
														{op.get(this.props, 'streams.0.mimeType', false) == "video/mp4" &&
															<Download status={this.state.status} key={button} styles={controlStyles}  
															// amp={this.amp} 
															streams={this.props.streams}
															title={this.props.shareTitle}
															/>
														}
													</Fragment>
													)
												break;
											case 'fullscreen':
												return (<Fullscreen status={this.state.status} key={button} styles={controlStyles}  
													amp={this.amp} 
													isFullscreen={this.props.isFullscreen}
													onClick={(show) => {this.onFullscreenClick(show)}} />)
												break;
											case 'altAudio':
												if(this.amp.tracks.data.audioTracks.list.length > 1){
													return (
														<button 
															className={`${controlStyles.icon} ${controlStyles.altAudio}`} 
															style={{}} 
															onClick={() => {this.audioOverlayVisible('toggle')}} 
															title="Alt Audio Menu" />							
													)
												} else { return null }
												break;
											default:
												return null
												break;
										}
										
									})
								}
								</div>
								
							</div>
							{
								(!this.state.smallView) ?
									<ShareOverlay status={this.state.status} styles={controlStyles}  
										shareUrl={shareUrl}
										shareTitle={this.props.shareTitle}
										appStrings={appStrings}
										onShareLinkClick={(track) => {this.onShareLinkClick(track)}}
										smallView={this.state.smallView} 
										open={this.state.shareOpen} 
										offsetRight={this.shareRightPos} 
										enableShareAtTime={op.get(this.props.config, 'ui.shareAtTime', false)}
										showShareAtTime={(data) => {this.showShareAtTime(data)}}
										convertedTime={convertedTime}
										setShareTime={(e) => {this.setShareTime(e)}}
										/>
									: null
							}
						</div>
					)}
					</FocusWithin>
					{
						(this.state.smallView) ?
							<ShareOverlay status={this.state.status} styles={controlStyles}  
								shareUrl={shareUrl}
								shareTitle={this.props.shareTitle}
								appStrings={appStrings}
								onShareLinkClick={(track) => {this.onShareLinkClick(track)}}
								onCloseClick={() => {this.onShareCloseClick()}}
								smallView={this.state.smallView} 
								open={this.state.shareOpen} 
								offsetRight={this.shareRightPos}
								enableShareAtTime={op.get(this.props.config, 'ui.shareAtTime', false)}
								showShareAtTime={(data) => {this.showShareAtTime(data)}}
								convertedTime={convertedTime}
								setShareTime={(e) => {this.setShareTime(e)}}
								 />
							: null
					}
					{
						this.getPoster()
					}
				</div>
			}
		</Measure>
	)}
}

export default (Controls);