import React, { Component } from 'react';
import { logger } from '../logger';
import op from 'object-path';
import { CSSTransition, transit } from "react-css-transition";

import captionStyles from '../sass/captions.scss'

class CaptionScreen extends Component {
	
	constructor(props) {
		super(props);		
		
		this.state = {
			...this.props
		}

		this.firstPos = 0;

		CSSTransition.childContextTypes = {
			// this can be empty
		}

		this.handleTransitionComplete = this.handleTransitionComplete.bind(this);

		//:constructor - state:%o', this.state);
	}
	
	componentDidMount() {
		//logger.log('CaptionScreena:componentDidMount');
	}
	
	componentDidUpdate(prevProps, prevState, snapshot) {
		//logger.log('CaptionScreen: componentDidUpdate - this.props:%o', this.props);	
	}

	getClipAreaStyle() {
		let top = (this.props.baseRow - this.props.rollUpRows + 1) * this.props.rowHeight + 'px';
		let height = this.props.rollUpRows * this.props.rowHeight + 'px';
		//height = this.windowHeight + 'px';
		//logger.log('CaptionScreen:getClipAreaStyle - baseRow:%o rollUpRows:%o top:%o height:%o', this.baseRow, this.rollUpRows, top, height);
		
		return {
			top: top,
			height: height
		}
	}


	handleTransitionComplete() {
		this.transEnd = [...this.props.rows];

		if (this.props.onTransitionFinish){
			this.props.onTransitionFinish()
		}

	}

	
	render() {	
		
		let rows = this.props.rows;
		let rowPos = this.props.rowHeight * -1;

		if (this.firstPos != rows[0]['position']) {
			this.firstPos = rows[0]['position'];
			rowPos = 0;
		}
		//logger.log('CaptionScreen: render - props.rollup:%o firstPos:%o rowPos:%o', this.props.rollup, this.firstPos, rowPos);

		return (
			
			<div className={captionStyles.clipArea} style={this.getClipAreaStyle()}>
				<CSSTransition 
					defaultStyle={{ transform: `translate(0, 0)`, height: `${this.props.windowHeight}px` }}
					enterStyle={{ transform: transit(`translate(0, ${rowPos}px)`, 500, `linear`) }}
					leaveStyle={{ }}
					activeStyle={{ transform: `translate(0, ${rowPos}px)` }}
					active={this.props.rollup}
					onTransitionComplete={this.handleTransitionComplete}
				>
					<div id="rowArea" className={captionStyles.rowArea} >
					{
						rows.map((row, i) => {
							//logger.log('CaptionScreen: render - i:%o row:%o', i, row.text);
							let font_size = this.props.rowHeight - 2;
							return (
								<div id="row" 
									className={captionStyles.captionRow} 
									data-value="" 
									data-num={row.id} 
									key={row.id}
									style={{
										'left': `${row.left}`,
										'height': `${this.props.rowHeight}px`
									}}
								>
									<div className={captionStyles.captionText}
										style={{
											'lineHeight': `${this.props.rowHeight}px`,
											'fontSize': `${font_size}px`
										}}
									>{`${row.text}`}</div>
								</div>
							)
						})  
					}
					</div>
				</CSSTransition>
			</div>

		)
	}
}

export default (CaptionScreen);

