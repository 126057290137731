import React, { Component } from 'react';
import { logger } from '../logger';
import op from 'object-path';

export default class AudioTracks extends Component {
    constructor(props) {
		super(props);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClick(e, idx){
        logger.log("[AudioTracks] idx:%o", idx);
        this.props.switchAudio(idx);
    }

    handleClose(){
        this.props.closeHandler(false);
    }

    render() {
        let {styles, isOpen, selected, amp} = this.props;
        let audioTracks = op.get(amp, 'tracks.data.audioTracks.list', []);   
        // logger.log("[AudioTracks] audioTracks:%o", audioTracks);  
        // audioTracks = [1, 2, 3];

		let highlightColor = {color:this.props.colors.highlightColor}

        return (
            audioTracks.length > 0 && isOpen ?
            <div className={styles.audioTracks}>
                <div className={styles.inner}>
                    <div className={styles.audioTrackHeader}>
                        <div>Audio</div>
                        <div className={styles.audioTracksClose}>
                            <button 
                                className={`${styles.icon} ${styles.closex}`} 
                                style={{}} 
                                onClick={() => this.handleClose()} 
                                // data-state={data_state}
                                title="Close">
                            </button>
                        </div>
                    </div>
                    <div className={styles.trackList}>
                        <ul>
                            {audioTracks.map((i, idx) => {
                                return (
                                    <li 
                                        data-state={idx == selected ? `selected` : ``} 
										style={idx == selected ? highlightColor : {}}
                                        aria-selected={idx == selected ? 'true' : 'false'}
                                        onClick={(e) => this.handleClick(e, idx)}
                                        onKeyDown={(e) => {e.key === 'Enter' || e.key === ' ' ? this.handleClick(e, idx) : null}}
                                        tabIndex={0}
                                        aria-label={i.data.label}
                                        role={"option"}
                                    >
                                        {`${i.data.label}`}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div> : null
        );
    }
}