import op from 'object-path';
import { logger } from './logger';

export function AmpConfig(obj, video, base) {
	logger.info('AmpConfig: obj:%o', obj);
	logger.info('AmpConfig: media:%o', video);

	let media = getSource(obj, video);
	let policy = op.get(video, 'autoplayPolicy', 'none');
	// convert mutedinline to just muted
	policy = policy == 'mutedinline' ? 'muted' : policy;

	let config = {
		debug: op.get(obj, 'ampDebug', false),
		hls: {
			debug: op.get(obj, 'hlsDebug', false),
			data: { 
				capLevelToPlayerSize: true,
				enableWebVTT: op.get(video, 'enableWebVTT', false),
			},
			quality: {
				startLevel: op.get(obj, 'startLevel', -1)
			}
		},
		paths: {
			player: `${base}amp/akamai/amp/core/`,
			//resources: `${base}amp/resources/`,
			plugins: `${base}amp/akamai/amp/`
		},
		media: {
			title : video.title,
			poster : video.poster,
			source : media,
			track : op.get(video, 'tracks', []),
			startTime: op.get(video, 'startSeconds', 0),
			temporalType: media ? media[0].streamType : '',
			// metadata: {
			// 	config: {
			// 		autoplay: true
			// 	}
			// }
		},
		captioning: {
			enabled: true,
			useparallelrendering: op.get(obj, 'ampCaptions', false),
			renderer: 'native'
		},
		tooltipText: {
			...video.toolTipText
		},
		autoplay : op.get(video, 'autoplay', false),
		autoplayPolicy: policy,
		playsinline: op.get(video, 'playsinline', false),
		loop: op.get(video, 'loop', false),
		fullscreen: {
			mode: obj.fullscreenMode
		},
		settings: {
			defaults: {
				captions: {
					fontFamily: "Arial, Helvetica, sans-serif",
					fontSize: "1em",
					fontColor: "white",
					fontOpacity: "100%",
					edgeType: "rightShadow",
					edgeColor: "black",
					edgeOpacity: "50%",
					backgroundColor: "black",
					backgroundOpacity: "00%",
					windowColor: "black",
					windowOpacity: "50%",
					scroll: "popout"
				}
			}
		},
		flash: {
			resources: [
				{
					id: "AkamaiAdvancedStreamingPlugin",
					src: "//sslplayers-vh.akamaihd.net/flash/plugins/osmf/advanced-streaming-plugin/v3.10/osmf2.0/AkamaiAdvancedStreamingPlugin.swf",
					absolute: true,
					host: "osmf",
					main: "com.akamai.osmf.AkamaiAdvancedStreamingPluginInfo",
					type: "application/x-shockwave-flash",
					enable:false
				}
			]
		}

		// ,getPlaybackOrder: function (order) {
        //     logger.info('AmpConfig: config - getPlaybackOrder:%o', order);
        //     if (/Safari/.test(navigator.userAgent))
        //       	return ["hls", "dash", "dvr", "default", "audio"]
        // }

	};

	config = configurePlugins(config, obj, video, media[0].streamType);
	let adTag = op.get(config, 'plugins.ima.adTagUrl', null);
	if (adTag) {
		config.adTagUrl = adTag;
	}

	logger.info('AmpConfig: config:%o', config);

	return config;
}

/**
 * get the appropriate stream url and mimetype
 *  - check for cdn percentages
 */
const getSource = (obj, video) => {
	// if only one stream defined, use it
	if (video.streams.length == 1) {
		return [{
			src : video.streams[0].url,
			type : video.streams[0].mimeType,
			streamType : video.streams[0].streamType
		}]
	}

	// if more than one stream defined
	else {
		let assignedPer = (Math.random() * 100); 
		let cdn = '';
		let comparePer = 0;
		let i=0;

		// if one cdn has 100 percent, default to it
		for (i=0; i<obj.cdns.length; i++) {
			if (cdn == '' && obj.cdns[i].percent == 100) {
				cdn = obj.cdns[i].id;
			}
		}

		// if cdn not set, meaning should have percentages against each, select one
		if (cdn == '') {
			for (i=0; i<obj.cdns.length; i++) {
				comparePer += obj.cdns[i].percent
				if (i == obj.cdns.length-1 && comparePer < 100) {
					comparePer = 100;
				}

				if (cdn == '' && assignedPer < comparePer) {
					cdn = obj.cdns[i].id;
				}
			}
		}
		
		//logger.info('getSource - assignedPer:%o cdn:%o', assignedPer, cdn);
		
		// select stream based on cdn, or default to first one if not found
		if (cdn) {
			for (i=0; i<video.streams.length; i++) {
				if (cdn == video.streams[i].cdn){
					return [{
						src : video.streams[i].url,
						type : video.streams[i].mimeType,
						streamType : video.streams[i].streamType
					}]
				}
			}
		}
		else {
			return [{
				src : video.streams[0].url,
				type : video.streams[0].mimeType,
				streamType : video.streams[0].streamType
			}]
		}
	}
}

const configurePlugins = (config, obj, video, type) => {
	//logger.info('configurePlugins - type:%o obj:%o video:%o', type, obj, video);

	config['plugins'] = {};

	let convivaEnable = op.get(obj, 'analytics.conviva.enable', []);
	let convivaKey = op.get(obj, 'analytics.conviva.key', false);
	let serviceUrl = op.get(obj, 'analytics.conviva.serviceUrl', false);

	let ads = op.get(video, 'ads', []);

	let controls = op.get(obj, 'controls.type', 'default');
	let customReactJs = op.get(obj, 'controls.js', '');
	let customReactCss = op.get(obj, 'controls.css', '');

	let convivaType = false;
	for (let i=0; i<convivaEnable.length; i++) {
		if (convivaEnable[i] == type) {
			convivaType = true;
		}
	}

	//logger.info('configurePlugins - convivaType:%o key:%o, serviceUrl:%o', convivaType, convivaKey, serviceUrl);
	if (convivaType && convivaKey && serviceUrl) {
		config['plugins']['conviva'] = {
			resources: [{
				//src: "#{paths.plugins}conviva/libs/conviva-core-sdk-2.121.0.32738.min.js",
				//src: "#{paths.plugins}conviva/libs/conviva-core-sdk-2.151.0.36981.min.js",
				//src: "#{paths.plugins}conviva/libs/conviva-core-sdk-2.146.0.36444.min.js",
				src: "#{paths.plugins}conviva/libs/conviva-core-sdk.min.js",
				type: "text/javascript",
				async: true
			}, {
				//src: "#{paths.plugins}conviva/libs/conviva-html5native-impl.js",
				src: "#{paths.plugins}conviva/libs/conviva-html5-sdk.min.js",
				type: "text/javascript",
				async: true
			}, {
				src: "#{paths.plugins}conviva/Conviva.js",
				type: "text/javascript",
				async: true
			}],
			customerKey: convivaKey,
			serviceUrl: serviceUrl,
			metadata: {
				//streamType and streamURL is set logically in the code
				applicationName: "HTML5",
				viewerId: "conviva_tester",
				assetName: "#{media.title}",
				deviceType: op.get(obj, 'analytics.convivaData.deviceType', ''),
				cdnName: op.get(obj, 'analytics.convivaData.cdn', ''),
				playerName: op.get(obj, 'analytics.convivaData.playerName', ''),
				custom: op.get(obj, 'analytics.convivaData.custom', '')
			}
		}

		logger.info('configurePlugins - conviva:%o', config['plugins']['conviva']);
	}

	if (ads.length > 0 && ads[0].type == 'vast') {
		logger.info('configurePlugins - ad:%o', ads[0].tag);
		// config['ima'] = {
		// 	enabled: true,
		// 	disableCompanionAds: (!video.companionAds),
		// 	vpaidMode: "enabled",
		// 	disableFlashAds: true,
		// 	resources: [
		// 		{
		// 			type: "text/javascript",
		// 			src: "//imasdk.googleapis.com/js/sdkloader/ima3.js",
		// 			debug: "//imasdk.googleapis.com/js/sdkloader/ima3_debug.js"
		// 		}
		// 	],
		// 	adTagUrl: ads[0].tag,
		// 	companions: video.companionAds || []
		// }	
		
		config['plugins']['ima'] = {
			resources: [
			  	{src: "//imasdk.googleapis.com/js/sdkloader/ima3.js", debug: "//imasdk.googleapis.com/js/sdkloader/ima3_debug.js", type: "text/javascript", async: true},
			  	{src: "${paths.plugins}ima/Ima.min.js", debug: "${paths.plugins}ima/Ima.js", type: "text/javascript", async: true}
			],
			adTagUrl: ads[0].tag,
			disableCompanionAds: false,
			vpaidMode: "enabled",
			disableFlashAds: true,
			companions: video.companionAds || []
		}
	}

	if (controls !== 'default' && controls !== 'react' && controls !== 'native') {
		config['plugins']['react'] = {
			enabled: false
		}
	}
	else if (controls == 'react' && customReactJs && customReactCss) {
		config['plugins']['react'] = {
			enabled: true,
			resources: [
				{
					src: "#{paths.plugins}react/libs/react.min.js",
					debug: "#{paths.plugins}react/libs/react.js",
					type: "text/javascript"
				},
				{
					src: customReactCss,
					type: "text/css"
				},
				{
					src: customReactJs,
					type: "text/javascript"
				}
			],
			autoHide: 5,
			adMode: "auto",
			mode: "auto"
		}
	}
	else if (controls == 'native') {
		config['controls'] = true;
	}
	else {
		config['plugins']['react'] = {
			enabled: true,
			resources: [
				{
					src: "#{paths.plugins}react/libs/react.min.js",
					debug: "#{paths.plugins}react/libs/react.js",
					type: "text/javascript"
				},
				{
					src: "#{paths.plugins}react/React.min.css",
					debug: "#{paths.plugins}react/React.css",
					type: "text/css"
				},
				{
					src: "#{paths.plugins}react/React.min.js",
					debug: "#{paths.plugins}react/React.js",
					type: "text/javascript"
				}
			],
			autoHide: 5,
			adMode: "auto",
			mode: "auto"
		};
	}

	config['customControls'] = {
		mode : controls == 'native' ? 'none' : controls
	};


	if (obj.captionSettings) {
		config['settings'] = obj.captionSettings
	}

	logger.info('configurePlugins - config:%o', config);

	return config;
};